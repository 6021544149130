<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 px-3 pt-3">
          <div class="col-12 col-xl-6 mx-0 mb-2 mb-xl-0">
            <p class="font-1-2s font-600">Edit Attribute Group</p>
          </div>
          <div class="col-12 col-xl-6 text-right ">
            <button
              type="button"
              class="btn btn-sm bt-main  px-3"
              @click="goto()"
            >
              Back
            </button>
          </div>
        </div>
        <div class="row m-0 px-3">
          <div class="col-12">
            <p class=" font-1-2s font-600 text-main">
              {{ data.catName + ` (${data.catCode})` }}
            </p>
          </div>
          <div class="col-12">
            <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
              <b-tab title="Properties" :title-link-class="linkClass(0)" active>
                <div class="row m-0 p-0">
                  <div class="col-12 mx-0 mt-3 px-3">
                    <p class="font-main font-600">Label Translation</p>
                    <hr />
                  </div>
                  <div class="col-12 col-xl-7">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Label Thailand</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="data.labelTH"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-xl-7">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Label English</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="data.labelEN"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-xl-7">
                    <b-form-checkbox
                      v-model="data.active"
                      name="check-button"
                      switch
                      value="1"
                    >
                      Active
                    </b-form-checkbox>
                  </div>
                  <div class="col-12 mx-0 mt-4 mb-3 px-3 ">
                    <button
                      type="button"
                      class="btn btn-sm bt-main  px-3"
                      @click="save()"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Attributes" :title-link-class="linkClass(1)">
                <GroupAttributelist :key="data.attrGroupId"  />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupAttributelist from "@/components/Manage/Attributes/GroupAttributelist.vue";
export default {
  name: "AttributesGroupEdit",
  components: { GroupAttributelist },
  data() {
    return {
      tabIndex: 0,
      data: {
        AttrName: "",
        labelEN: "",
        labelTH: "",
        attrGroupId: "",
        attrId: "",
        active: "",
        catCode: "",
        catName: "",
      },
    };
  },
  watch: {
      "data.active": function(v) {
      if (!v) {
        this.data.active = 0;
      }
    },
  },
  mounted() {
    this.getdata_list();
  },
  methods: {
    async getdata_list() {
      try {
        if (!!this.$route.query?.attrGroupId) {
          let data = {
            catCode: "",
            attrGroupId: this.$route.query?.attrGroupId,
          };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/get_attribute_groups`,
            data,
            1
          );

          let {
            labelEN,
            labelTH,
            attrGroupId,

            active,
            catName,
            catCode,
          } = getAPI.data[0];
          this.data.catCode = catCode;
          this.data.catName = catName;
          this.data.labelEN = labelEN;
          this.data.labelTH = labelTH;
          this.data.attrGroupId = attrGroupId;
          this.data.active = active;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async validate() {
      return new Promise(async (resolve, reject) => {
        let {  labelTH, labelEN } = this.data;
    
        if (!!!labelTH) {
          resolve(`Please enter labelTH / กรุณาระบุ labelTH`);
        }
        if (!!!labelEN) {
          resolve(`Please enter labelEN / กรุณาระบุ labelEN`);
        }
        resolve("OK");
      });
    },
    async save() {
      try {
        let vali = await this.validate();

        if (vali !== "OK") {
          throw vali;
        }
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/add_attribute_group`,
            this.data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.getdata_list();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    goto() {
      this.$router.push({ name: "Attribute-group" });
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
  },
};
</script>

<style></style>

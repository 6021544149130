<template>
  <b-modal
    id="ModalAddAttribute"
    hide-footer
    no-close-on-backdrop
    title="Add Attributes"
    size="xl"
    scrollable
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Search</label>
          <input
            type="search"
            class="form-control form-control-sm"
            v-model="find"
            @keyup="Search()"
          />
        </div>
      </div>
      <div class="col-12">
        <p class="font-600">
          จำนวน&nbsp;
          <span class="text-main">{{
            new Intl.NumberFormat().format(items.length)
          }}</span>
          &nbsp;รายการ
        </p>
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          sticky-header
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(Group)="row">
            {{ row.item.labelTH_Group + ` / ` + row.item.labelEN_Group }}
          </template>
          <template #cell(active)="row">
            <span
              :class="[row.item.active == 1 ? 'badge-main' : 'badge-twd']"
              class="badge"
              >{{ row.item.active == 0 ? "No" : "Yes" }}</span
            >
          </template>
          <template #cell(Action)="row">
            <button
              type="button"
              class="btn btn-sm bt-main"
              @click="add(row.item)"
            >
              Add
            </button>
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalAddAttribute",
  data() {
    return {
      items: [],
      itemlist: [],
      find: "",
      fields: [
        { key: "No", label: "No", class: "text-center" },
        { key: "attrname", label: "attributeName" },
        { key: "attrlabelTH", label: "labelTH" },

        { key: "filterType", label: "filterType", class: "text-center" },
        { key: "active", label: "Active", class: "text-center" },

        // { key: "catCode", label: "catCode" },
        // { key: "SubDept", label: "SubDept" },

        {
          key: "Action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  mounted() {
    this.getdata_list();
  },
  methods: {
    async add(e) {
      try {
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          e.active = 1;
          let data = {
            attrGroupId: this.$route.query?.attrGroupId,
            ...e,
            mode: "add",
          };

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `attribute/mapping_attribute`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.$emit("get_data");
          this.hide();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        let key = ["attrlabelTH", "attrname"];
        for (var i = 0; i < objects.length; i++) {
          for (let k of key) {
            // console.log(k);
            var index = String(objects[i][k])
              .trim()
              .toLocaleUpperCase()
              .replace(/ /g, "");

            if (
              index.indexOf(toSearch) != -1 &&
              results.findIndex((e) => e === objects[i]) === -1
            ) {
              results.push(objects[i]);
            }
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async getdata_list() {
      try {
        let data = { attrGroupId: "", attrId: "" };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute`,
          data,
          1
        );
        // console.log(getAPI.data);
        this.items = getAPI.data;
        this.itemlist = getAPI.data;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    hide() {
      this.$bvModal.hide("ModalAddAttribute");
    },
  },
};
</script>

<style></style>
